.text-muted-custom{
 
        --bs-text-opacity: 1;
        color: #6c757d!important;

}

.header-title{
        height: 39px;
        color: #fff;
        font-size: 30px;
        font-weight: 650;
   
}
.slick-slide {
        transform: scale(0.9);
        transition: transform 0.5s; /* Target only the transform property for transition */
        padding: 0;
    }
    
    .slick-slide.slick-center {  /* Target the centered slide instead of .slick-active */
        transform: scale(1.0);
        transition: transform 0.5s;
    }
@media (min-width:576px) {
        
        
        .portfolio-image{
                height: 750px ;
              }
}  

.project-details-modal {
        img.img-fluid {
                height: 400px;
                object-fit: cover;
        }
        @media (max-width:548px){
              img.img-fluid {
                height: 300px;
                object-fit: cover;
        }  
        }
}
